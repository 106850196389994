import request from '@/utils/request'

// 查询客户续期详情
export function findAgentRenewOrder(id) {
  return request({
    url: `/agent_renew_orders/${id}`,
    method: 'get'
  })
}

// 查询客户续期详情卡号列表
export function findSimCardRenewOrders(id, params) {
  return request({
    url: `/agent_renew_orders/${id}/sim_card_renew_orders`,
    method: 'get',
    params: params
  })
}

// 导出续期详情卡号列表
export function exportSimCardRenewOrders(id, data) {
  return request({
    url: `/agent_renew_orders/${id}/sim_card_renew_orders/export`,
    method: 'post',
    data
  })
}

